const { auth } = require('./auth');
const eventHandlers = require('./eventHandlers');
const { announcements }  = require('./announcements');
const { newsletter } = require('./newsletter');

/**
 * Main Init module to Initialize all other modules
 */

module.exports = {
    init(name) {
        // Require polyfills

		require('svgxuse');

        // Initialize all modules

        auth.init(name);
        eventHandlers.init();
        newsletter.init();
        announcements.init(name);
    },
};
