import $ from 'jquery';
window.jQuery = $; window.$ = $;
import uikit from 'uikit';
import uikitLightbox from '../../../node_modules/uikit/src/js/components/lightbox.js';
import LazyLoad from '../../../node_modules/vanilla-lazyload/dist/lazyload.amd.min.js';
import tooltipster from 'tooltipster';
const allModules = require('./assets/scripts/initialize');
import './assets/styles/main.scss';

$(document).ready(() => {
	allModules.init('serpwatcher');
});
